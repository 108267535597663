@font-face {
	font-family: 'Inter';
	src: local("Inter"),
	  	url('./assets/fonts/Inter-Light.ttf') format("truetype");
}

button {
	padding: 0px;
	background-color: transparent;
	border: 0px;
}

button:active:not([disabled], .MuiButtonBase-root) {
    background-color: #020A041A;
	border-radius: 5px;
	border-top-right-radius: 0px;
	border-top-left-radius: 0px;
}

::placeholder {
	color: #020A0466;
	opacity: 1;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
	color: #020A0466;
}

::-ms-input-placeholder { /* Microsoft Edge */
	color: #020A0466;
}

::-webkit-scrollbar {
	width: 10px;
	height: 10px;
}
::-webkit-scrollbar-button:start:decrement,
::-webkit-scrollbar-button:end:increment  {
	height: 1px;
	background-color: transparent;
}
::-webkit-scrollbar-track-piece  {
	background-color: #eeeeee;
	-webkit-border-radius: 16px;
}
::-webkit-scrollbar-thumb:vertical {
	height: 10px;
	background-color: #666;
	border: 1px solid #eee;
	-webkit-border-radius: 16px;
}