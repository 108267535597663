div.login_page {
	display: flex;
	justify-content: center;
	align-items: center;
}

.login_page form.login {
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border: 1px solid;
	border-radius: 10px;
	border-color: #020A041A;
	padding: 48px;
	gap: 24px;
	width: 424px;
}

.login_page label.title {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 600;
	font-size: 32px;
	line-height: 39px;
	display: flex;
	align-items: center;
	text-align: center;
	color: #1D7B33;
    user-select: none;
}

@media screen and (max-width: 450px) {
	div.login_page form.login {
		width: auto;
		border: 0px;
	}
  }