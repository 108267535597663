div.header {
    display: flex;
    flex-direction: row;
    border-bottom: solid 1px;
    border-bottom-color: #020A041A;
    justify-content: space-between;
    user-select: none;
    background-color: #FFFFFF;
    z-index: 1;
    position: relative;
}
div.header div.language {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 16px;
    padding: 16px 32px;
}
div.header button.language {
    display: inline-block;
    position: relative;
    border: 0px;
    background-color: transparent;
    font-family: 'BPG Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #7C7D7E;
    cursor: pointer;
}
div.header button.language:after {
    content:'';
    position:absolute;
    top:-10px; bottom:-10px; 
    left:-10px; right:-10px;
}
div.header button.language.selected {
    font-family: 'Inter';
    line-height: 15px;
    color: #1D7B33;
}
div.header label.title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #1D7B33;
    padding: 16px 32px;
}
div.header div.big_row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
}
div.header div.border {
    width: 1px;
    height: 45px;
    background-color: #020A041A;
    border: 0px;
    align-self: center;
}
button.header_row {
    align-items: center;
    justify-content: center;
    display: flex;
    gap: 8px;
    padding: 16px 32px;
}
label.header_row_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    color: #020A04;
}
label.header_row_title.selected {
    color: #1D7B33;
}
div.header button.item.padding.big {
    padding: 16px 32px;
}
div.header button.item.padding.little {
    padding: 16px 16px;
}

@media (min-width: 865px) and (max-width: 1056px) {
    div.header :where(.header_row, .title) {
        padding: 16px 16px !important;
    }
}

@media (min-width: 769px) and (max-width: 864px) {
    div.header :where(.header_row, .title) {
        padding: 16px 16px !important;
    }
    div.header :where(img.hide_image) {
        display: none;
    }
}

@media (min-width:659px) and (max-width: 768px) {
    div.header :where(.header_row, .title) {
        padding: 16px 8px !important;
    }
    div.header :where(img.hide_image) {
        display: none;
    }
}

@media (max-width: 658px) {
    div.header :where(.header_row) {
        padding: 16px 12px !important;
    }
    div.header :where(label.header_row_title){
        display: none;
    }
    label#logout {
        display: flex;
    }
    div.header label.title {
        padding: 16px 8px;
    }
}