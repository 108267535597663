div.dashboard_item {
	flex: 332px;
	max-width: 332px;
	width: 332px;
	height: 192px;
	display: flex;
	position: relative;
	border: 1px solid rgba(2, 10, 4, 0.1);
	border-radius: 5px;
	overflow: hidden;
  flex-direction: column;
}

button.button {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 16px 0px;
	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: #1D7B33;
	user-select: none;
	width: 100%;
}

div.info {
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 16px;
	border-bottom: 1px solid rgba(2, 10, 4, 0.1);
	width: 100%;
	flex-grow: 1;
  height: 200px;
}

div.row {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: 16px;
	width: 100%;
	height: fit-content;
}

div.dashboard_item label.title {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 500;
	font-size: 17px;
	line-height: 21px;
	color: #020A04;
	user-select: none;
}

label.description {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: #7C7D7E;
	user-select: none;
}
