@media (max-width: 658px) {
    div#create_page div.row {
		flex-direction: column;
	}
}

.new__guest__title {
  display: flex;
  align-items: center;

  font-size: 16px;
  line-height: 19px;
  color: #020A04;
}

.new__guest__wrapper {
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: space-between;
}

.new__guest__container {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.new__guest__container-price {
  display: flex;

  justify-content: flex-end;
}

.new__guest__price {
  padding: 10px;

  background-color: #7C7D7E1A;
  font-size: 14px;

  border-radius: 5px;
}

.icon {
  cursor: pointer;
}
