div.input_component {
	position: relative;
	display: flex;
	width: 100%;
	height: fit-content;
	flex-direction: column;
	align-items: stretch;
	gap: 8px;
}

div.input_component label.title {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	display: flex;
	align-items: center;
	color: #020A04;
    user-select: none;
}

div.input_component .input {
	display: flex;
	flex: 1;
	flex-direction: row;
	align-items: center;
	padding: 16px;
	gap: 10px;
	background: #FFFFFF;
	border: 1px solid rgba(2, 10, 4, 0.1);
	border-radius: 5px;
	resize: none;
	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
}

div.input_component .input:focus {
	outline: #1D7B33;
	border-color: #1D7B33;
}

div.input_component .input:disabled {
	background: #7C7D7E1A;
	color: #7C7D7E99;
}

div.input_component button {
	position: absolute;
	right: 12px;
	top: 8px;
	padding: 4px;
}

div.input_component button:active {
    background-color: #020A041A;
	border-radius: 5px;
}

.description {
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  margin-top: 8px;
  gap: 8px;
}

.text_description {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
}
