div.dashboard_item_animation {
	flex: 332px;
	max-width: 332px;
	width: 332px;
	height: 192px;
	background-color: #00000005;
	border-radius: 5px;
	row-gap: 13px;
	padding: 16px;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	position: relative;
	box-sizing: border-box;
}

div.dashboard_item_animation div.animated {
	background-color: #00000010;
	transition: 0.4s;
}

div.dashboard_item_animation div.circle {
	width: 26px;
	height: 26px;
	border-radius: 26.6667px;
	justify-self: start;
}

div.dashboard_item_animation div.big_row {
	width: 240px;
	height: 14.28px;
	border-radius: 20.4px;
}

div.dashboard_item_animation div.row {
	height: 12px;
	border-radius: 16.8px;
}

div.dashboard_item_animation div.first {
	width: 120px;
}

div.dashboard_item_animation div.second {
	width: 32px;
}

div.dashboard_item_animation div.third {
	width: 180px;
}

div.dashboard_item_animation div.fourth {
	width: 56px;
}

div.dashboard_item_animation div.fifth {
	width: 160px;
	align-self: center;
	position: absolute;
	bottom: 16px;
}

div.dashboard_item_animation div.animated.active {
	opacity: 0.4;
}

div.dashboard_item_animation div.animated.inactive {
	opacity: 1;
}