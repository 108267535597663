div.list_item {
	flex: 330px;
	max-width: 330px;
	height: 200px;
	display: flex;
	position: relative;
	border: 1px solid rgba(2, 10, 4, 0.1);
	border-radius: 5px;
	overflow: hidden;
  flex-direction: column;
}

div.list_item label.item_title {
  margin-top: 8px;
	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: #020A04;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	user-select: none;
}
