div.details.cell {
	border-bottom: 1px solid rgba(2, 10, 4, 0.1);
	padding-bottom: 16px;
	gap: 16px;
	display: grid;
}

div.details.cell label.description {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: #7C7D7E;
}

div.details.cell label.item_title {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: #020A04;
}