div.editable_cell {
	flex: 1;
	display: flex;
	flex-direction: column;
	gap: 8px;
}

div.editable_cell label.title{
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #020A04;
}

div.editable_cell .select__control,
div.editable_cell .picker {
	height: 49px;
}
