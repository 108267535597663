div.dashboard_page {
	display: flex;
	flex-direction: column;
	flex-grow: 1;

	padding: 24px 32px;

	gap: 16px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
}

div.dashboard_page label.title {
  display: flex;
  align-items: center;

	font-weight: 600;
	font-size: 17px;

	line-height: 21px;

	color: #020A04;
	user-select: none;
}

div.dashboard_page div.content {
	display: flex;
	flex-direction: row;

	justify-content: center;
	flex-wrap: wrap;

	gap: 16px;
}

.ptr {
	flex: 1;
}
