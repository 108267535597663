button.button_component {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 15px 24px;
	gap: 10px;
	background: #1D7B33;
	border-radius: 5px;
	color: #FFFFFF;
	border: 0px;
	cursor: pointer;
}

button.button_component:active {
	background-color: #1D7B3399;
}

button.button_component:disabled {
	background-color: #7C7D7E1A;
	color: #7C7D7E99;
}