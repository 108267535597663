div.list_item_animation {
	flex: 300px;
	max-width: 300px;
	width: 300px;
	height: 145px;
	background-color: #00000005;
	border-radius: 5px;
	gap: 13px;
	padding: 16px;
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
}

div.list_item_animation div.animated {
	transition: 0.4s;
	height: 12px;
	background: rgba(2, 10, 4, 0.1);
	border-radius: 16.8px;
}

div.list_item_animation div.first {
	width: 64px;
}

div.list_item_animation div.second {
	width: 33px;
}

div.list_item_animation div.third {
	width: 81px;
}

div.list_item_animation div.fourth {
	width: 27px;
}

div.list_item_animation div.fifth {
	width: 32px;
}

div.list_item_animation div.sixth {
	width: 92px;
}

div.list_item_animation div.seventh {
	width: 73px;
	bottom: 16px;
	position: absolute;
}

div.animated.active {
	opacity: 0.4;
}

div.animated.inactive {
	opacity: 1;
}