div.modal {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: absolute;
	width: 264px;
	height: 172px;
	top: 72px;
	right: 16px;
	background: #FFFFFF;
	box-shadow: 2px 2px 3px 4px rgba(0, 0, 0, 0.3);
	border-radius: 5px;
	padding: 8px 16px;
}

div.modal div.row.first {
	flex-direction: column;
	align-items: flex-start;
	gap: 4px;
	flex: 1;
	margin: 16px 0px;
	margin-bottom: 16px;
}

div.modal div.row.first label.userName {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 500;
	font-size: 17px;
	line-height: 21px;
	color: #020A04;
}

div.modal div.row.first label.userType {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: #7C7D7E;
}

div.modal div.row.second {
	flex: 1;
	padding: 16px 0px;
	justify-content: center;
	border-bottom: 1px solid rgba(2, 10, 4, 0.1);
	border-top: 1px solid rgba(2, 10, 4, 0.1);
}

div.modal button.logout {
	flex: 1;
	padding: 12.5px 0px;
	justify-content: center;
	gap: 8px;
	width: 264px;
	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	display: flex;
	align-items: center;
	text-align: center;
	color: #020A04;
}

div.modal button.logout:active {
    background-color: #020A041A;
}